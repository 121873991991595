import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../assets/CheapestPint.svg';
import logoDark from '../assets/CheapestPintDark.svg';
import { useTheme } from '../utils/ThemeContext';

const HamburgerMenu = ({ isOpen, onClose, reportedPints, currentLevel }) => {
  const { isDark } = useTheme();

  if (!isOpen) return null;

  const levelNumber = currentLevel.name.split(' - ')[0];

  return (
    <div className="fixed inset-0 bg-gray-800 bg-opacity-75 z-[1100] dark:bg-gray-900 dark:bg-opacity-80">
      <div className="fixed right-0 top-0 h-full w-64 bg-white shadow-lg overflow-y-auto dark:bg-gray-800">
        <div className="p-4">
          <button
            onClick={onClose}
            className="absolute top-4 right-4 text-gray-600 hover:text-gray-800 dark:text-gray-300 dark:hover:text-gray-100"
            aria-label="Close menu"
          >
            <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12"></path>
            </svg>
          </button>
          <img src={isDark ? logoDark : logo} alt="CP logo" className="w-24 mb-6" />
          <nav>
            <ul className="space-y-4">
              <div className="border border-gray-200 p-4 rounded-lg flex flex-col dark:border-gray-700">
                <li>
                  <Link
                    to="/my-pints"
                    className="block underline text-green-700 hover:text-green-600 mb-2 dark:text-green-400 dark:hover:text-green-300"
                    onClick={onClose}
                  >
                    <span className="text-2xl mr-2" role="img" aria-hidden="true">🍺</span>
                    Your pints: {reportedPints}  
                  </Link>
                </li>
                <li>
                  <div className="block text-gray-800 dark:text-gray-300">
                    <span className="text-2xl mr-2" role="img" aria-hidden="true">🏆</span>
                    {levelNumber}
                  </div>
                </li>
              </div>
              <div className='divide-y divide-gray-200 dark:divide-gray-700'></div>
              <li>
                {/* <a
                  href="https://docs.google.com/forms/d/e/1FAIpQLSdyHWtxGXpyDhx6d8hSgHdk_eDNzHLLUJhJuF3-PDb7_SVy5Q/viewform?usp=sf_link"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="block text-gray-800 hover:text-green-600 dark:text-gray-300 dark:hover:text-green-400"
                  aria-label="Give feedback"
                >
                  <span className="text-2xl mr-2" role="img" aria-hidden="true">🗣️</span>
                  Give feedback
                </a> */}
              </li>
              <li>
                <a
                  href="https://buymeacoffee.com/averagepint"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="block text-gray-800 hover:text-green-600 dark:text-gray-300 dark:hover:text-green-400"
                  aria-label="Buy me a pint"
                >
                  <span className="text-2xl mr-2" role="img" aria-hidden="true">🍺</span>
                  Buy me a pint
                </a>
              </li>
              <li>
                <Link
                  to="/privacy-policy"
                  className="block text-gray-800 hover:text-green-600 dark:text-gray-300 dark:hover:text-green-400"
                  onClick={onClose}
                >
                  Privacy Policy
                </Link>
              </li>
              <li>
                <Link
                  to="/faqs"
                  className="block text-gray-800 hover:text-green-600 dark:text-gray-300 dark:hover:text-green-400"
                  onClick={onClose}
                >
                  FAQs
                </Link>
              </li>
            </ul>
            <div className="bg-green-100 text-left mt-8 p-2 lg:px-4 dark:bg-green-900">  
              <span className="text-xs mr-2 text-left text-green-700 flex-auto max-w-screen-lg dark:text-green-300">A pint with a ⭐️ has been reported at the same price, 3 or more times</span>
            </div>
            <div className="bg-blue-100 dark:bg-blue-900 p-2 lg:px-4 mt-8">
          <div className="flex flex-col">
            <button
              className=" text-left font-default text-xs text-blue-700 dark:text-blue-200 font-bold hover:text-blue-800 dark:hover:text-blue-100"
            >
              <span>Disclaimer: Pint prices listed here are crowdsourced and may not always be accurate or up-to-date</span>
              <span className="ml-2"></span>
            </button>
            
              <span className="font-default text-xs mt-2 text-blue-700 dark:text-blue-200">
                Please confirm with the venue for current pricing. Also, please be as honest as possible when submitting prices, it helps everyone!
              </span>
            
          </div>
        </div>
            <p className="text-xs text-gray-500 text-sm my-4 dark:text-gray-400">© Copyright 2024 Cheapest Pint</p>
          </nav>
        </div>
      </div>
    </div>
  );
};

export default HamburgerMenu;