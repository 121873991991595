import React, { useState, useEffect, useRef } from 'react';

const PintFilterChips = ({ pubs, onFilterChange }) => {
  const [selectedPint, setSelectedPint] = useState('All');
  const scrollContainerRef = useRef(null);
  const [isLoading, setIsLoading] = useState(true);
  const [pintCounts, setPintCounts] = useState([]);

  // Calculate most popular pints
  const getPintCounts = () => {
    const counts = { All: 0 };
    pubs.forEach(pub => {
      pub.pints.forEach(pint => {
        const beerType = pint.beerType;
        counts[beerType] = (counts[beerType] || 0) + 1;
        counts.All += 1;
      });
    });

    // Convert to array and sort by count
    return [
      { beerType: 'All', count: counts.All },
      ...Object.entries(counts)
        .filter(([key]) => key !== 'All')
        .map(([beerType, count]) => ({ beerType, count }))
        .sort((a, b) => b.count - a.count)
        .slice(0, 24) // Get top 24 most popular pints (plus 'All' = 25 total)
    ];
  };

  useEffect(() => {
    if (pubs.length > 0) {
      setPintCounts(getPintCounts());
      setIsLoading(false);
    }
  }, [pubs]);

  const handleChipClick = (beerType) => {
    setSelectedPint(beerType);
    onFilterChange(beerType === 'All' ? null : beerType);
  };

  return (
    <div className="relative z-[1001] mx-auto">
      <button
        onClick={() => scrollContainerRef.current.scrollBy({ left: -200, behavior: 'smooth' })}
        className="absolute left-0 top-1/2 transform -translate-y-1/2 z-10 bg-white dark:bg-gray-800 p-2 rounded-full shadow-lg hover:bg-gray-100 dark:hover:bg-gray-700 md:block hidden"
        aria-label="Scroll left"
      >
        <svg 
          xmlns="http://www.w3.org/2000/svg" 
          className="h-5 w-5 text-gray-600 dark:text-gray-300" 
          fill="none" 
          viewBox="0 0 24 24" 
          stroke="currentColor"
        >
          <path 
            strokeLinecap="round" 
            strokeLinejoin="round" 
            strokeWidth={2} 
            d="M15 19l-7-7 7-7" 
          />
        </svg>
      </button>
      <div
        ref={scrollContainerRef}
        className="flex overflow-x-auto scrollbar-hide gap-2 px-4 md:px-8 py-2 max-w-full bg-white dark:bg-gray-800 rounded-lg shadow-sm cursor-grab active:cursor-grabbing"
        style={{ 
          scrollbarWidth: 'none', 
          msOverflowStyle: 'none',
          overscrollBehaviorX: 'contain',
          scrollSnapType: 'x mandatory'
        }}
      >
        {isLoading ? (
          Array.from({ length: 6 }).map((_, index) => (
            <div key={index} className="animate-pulse whitespace-nowrap px-3 md:px-4 py-2 rounded-full text-sm font-medium scroll-snap-align-start bg-gray-300 dark:bg-gray-700 w-32 h-10"></div>
          ))
        ) : (
          pintCounts.map(({ beerType, count }) => (
            <button
              key={beerType}
              onClick={() => handleChipClick(beerType)}
              className={`
                whitespace-nowrap px-3 md:px-4 py-2 rounded-full text-sm font-medium scroll-snap-align-start
                ${selectedPint === beerType
                  ? 'bg-green-600 text-white'
                  : 'bg-gray-100 text-gray-800 hover:bg-gray-200 dark:bg-gray-700 dark:hover:bg-gray-600 dark:text-gray-200'
                }
              `}
            >
              {beerType} ({count})
            </button>
          ))
        )}
      </div>
      <button
        onClick={() => scrollContainerRef.current.scrollBy({ left: 200, behavior: 'smooth' })}
        className="absolute right-0 top-1/2 transform -translate-y-1/2 z-10 bg-white dark:bg-gray-800 p-2 rounded-full shadow-lg hover:bg-gray-100 dark:hover:bg-gray-700 md:block hidden"
        aria-label="Scroll right"
      >
        <svg 
          xmlns="http://www.w3.org/2000/svg" 
          className="h-5 w-5 text-gray-600 dark:text-gray-300" 
          fill="none" 
          viewBox="0 0 24 24" 
          stroke="currentColor"
        >
          <path 
            strokeLinecap="round" 
            strokeLinejoin="round" 
            strokeWidth={2} 
            d="M9 5l7 7-7 7" 
          />
        </svg>
      </button>
    </div>
  );
};

export default PintFilterChips; 