import React from 'react';

function PrivacyPolicy() {
    return (

<body class="bg-gray-100 dark:bg-gray-900 text-gray-800 dark:text-gray-200">
    <div class="container mx-auto max-w-4xl p-6 sm:p-8">
        <div class="prose lg:prose-lg prose-indigo dark:prose-invert">
            <h1 class="text-3xl font-bold mb-4">Privacy Policy for CheapestPint.uk</h1>
            <p><strong>Last updated:</strong> 3 Jan 2025</p>
            <p>Welcome to CheapestPint.uk ("we," "us," "our," or the "Site"). Your privacy is important to us, and this Privacy Policy outlines how we collect, use, and protect your information when you use our services.</p>

            <h2 class="text-xl font-semibold mt-8 mb-4">1. Information We Collect</h2>
            <h3 class="text-lg font-medium mt-6 mb-2">1.1. Information You Provide</h3>
            <p>We may collect information you provide directly to us, such as when you submit pint prices, contact us, or engage with features on the Site.</p>

            <h3 class="text-lg font-medium mt-6 mb-2">1.2. Automatically Collected Information</h3>
            <p>When you visit our Site, we may automatically collect:</p>
            <ul class="list-disc pl-6 space-y-2">
                <li><strong>Device Information:</strong> Such as IP address, browser type, and operating system.</li>
                <li><strong>Location Data:</strong> To provide geolocation features for finding pint prices near you.</li>
                <li><strong>Usage Data:</strong> Such as pages viewed and time spent on the Site.</li>
            </ul>

            <h3 class="text-lg font-medium mt-6 mb-2">1.3. Third-Party Services</h3>
            <p>We use third-party services that may collect and process data:</p>
            <ul class="list-disc pl-6 space-y-2">
                <li><strong>Mapbox and OpenStreetMap APIs:</strong> For geolocation and mapping features.</li>
                <li><strong>Umami and Google Analytics:</strong> To collect anonymized analytics data for understanding Site usage and improving performance.</li>
                <li><strong>Google AdSense:</strong> To display relevant advertisements tailored to your interests.</li>
            </ul>

            <h2 class="text-xl font-semibold mt-8 mb-4">2. How We Use Your Information</h2>
            <p>We use the collected information to:</p>
            <ul class="list-disc pl-6 space-y-2">
                <li>Provide and improve our services.</li>
                <li>Enhance geolocation features for finding pint prices.</li>
                <li>Analyze usage patterns and improve the Site.</li>
                <li>Display advertisements and communicate with you about updates, promotions, or inquiries.</li>
            </ul>

            <h2 class="text-xl font-semibold mt-8 mb-4">3. Crowdsourced Data and Accuracy</h2>
            <p>All pint prices on CheapestPint.uk are crowdsourced. While we aim for accuracy, the data may not always be current or correct. Users are encouraged to verify prices and report inaccuracies.</p>

            <h2 class="text-xl font-semibold mt-8 mb-4">4. My Pints Feature</h2>
            <p>The "My Pints" section allows you to save your favorite pint entries locally within your browser.</p>
            <ul class="list-disc pl-6 space-y-2">
                <li><strong>Local Storage:</strong> All data in "My Pints" is stored locally on your device and is not transmitted to or stored on our servers.</li>
                <li><strong>No Account Required:</strong> You do not need to create an account to use this feature.</li>
                <li><strong>Data Loss:</strong> Clearing your browser’s cookies, history, or local storage will result in the loss of all saved entries in "My Pints."</li>
            </ul>

            <h2 class="text-xl font-semibold mt-8 mb-4">5. Sharing Your Information</h2>
            <p>We do not sell your personal information. However, we may share data with:</p>
            <ul class="list-disc pl-6 space-y-2">
                <li><strong>Service Providers:</strong> To deliver and enhance Site features (e.g., Mapbox, OpenStreetMap, Umami, Google Analytics, and Google AdSense).</li>
                <li><strong>Legal Authorities:</strong> When required by law.</li>
            </ul>

            <h2 class="text-xl font-semibold mt-8 mb-4">6. Cookies and Tracking Technologies</h2>
            <p>We use cookies and similar technologies to improve your experience. Third-party services like Google Analytics and Google AdSense may use cookies to collect usage data. You can manage cookies through your browser settings.</p>

            <h2 class="text-xl font-semibold mt-8 mb-4">7. Third-Party Links</h2>
            <p>Our Site may include links to third-party websites. We are not responsible for the privacy practices or content of these sites.</p>

            <h2 class="text-xl font-semibold mt-8 mb-4">8. Your Rights</h2>
            <p>Depending on your location, you may have the right to:</p>
            <ul class="list-disc pl-6 space-y-2">
                <li>Access, correct, or delete your data.</li>
                <li>Opt out of data processing for analytics purposes.</li>
            </ul>

            <h2 class="text-xl font-semibold mt-8 mb-4">9. Changes to This Privacy Policy</h2>
            <p>We may update this Privacy Policy periodically. Changes will be posted with an updated effective date.</p>

            <p class="mt-8"><strong>Contact us</strong> <u><a href="https://forms.gle/vjH4QYoVMRz9mGdC8" class="text-white">here</a></u></p>
        </div>
    </div>
</body>


    );
}

export default PrivacyPolicy;
