import React, { useState } from 'react';
import greenIcon from '../assets/green-icon.svg';
import orangeIcon from '../assets/orange-icon.svg';
import redIcon from '../assets/red-icon.svg';
import blackIcon from '../assets/black-icon.svg';
import star from '../assets/Star.png';
import PropTypes from 'prop-types';

const filterOptions = [
  { id: 'wetherspoons', name: '🥄 Include Wetherspoons', value: 'show' },
  { id: 'guinness', name: '🍺 Guinness In Stock (last 24 hours)', value: 'guinness' },
  { id: 'beer-garden', name: '🪴 Beer Gardens', value: 'beer-garden' },
  { id: 'live-music', name: '🎶  Live Music', value: 'live-music' },
  { id: 'live-sport', name: '⚽️ Live Sports', value: 'live-sport' },
];

const MapControlSheet = ({ onFilterChange, setSelectedPintFilter }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedFilters, setSelectedFilters] = useState({
    wetherspoons: 'show',
    amenities: [],
  });

  const handleFilterChange = (filterId, value) => {
    setSelectedFilters((prevFilters) => {
      let newFilters;
      if (filterId === 'wetherspoons') {
        newFilters = {
          ...prevFilters,
          wetherspoons: prevFilters.wetherspoons === 'show' ? 'hide' : 'show',
        };
      } else {
        const isAdding = !prevFilters.amenities.includes(value);
        newFilters = {
          ...prevFilters,
          amenities: isAdding
            ? [...prevFilters.amenities, value]
            : prevFilters.amenities.filter((item) => item !== value),
        };
        // Reset selectedPintFilter if 'guinness' filter is being turned on
        if (isAdding && value === 'guinness') {
          setSelectedPintFilter(null);
        }
      }
      onFilterChange(newFilters);
      return newFilters;
    });
  };

  return (
    <div className="leaflet-top leaflet-right">
      <div className="leaflet-control leaflet-bar">
        <button
          type="button"
          onClick={() => setIsOpen(true)}
          className="bg-white dark:bg-gray-800 text-green-700 dark:text-green-400 text-sm font-medium rounded-lg px-4 py-2 shadow-lg hover:bg-gray-50 dark:hover:bg-gray-700 transition-colors"
        >
          Filters and Key 🔑
        </button>

        {isOpen && (
          <>
            <div className="fixed inset-0 bg-black bg-opacity-50 z-[1001]" onClick={() => setIsOpen(false)} />
            <div
              className="fixed top-4 right-4 w-80 bg-white dark:bg-gray-800 rounded-lg p-4 shadow-xl z-[1002]"
              onClick={e => e.stopPropagation()}
            >
              <div className="flex justify-between items-center mb-4">
                <h2 className="text-lg font-bold text-green-700 dark:text-green-400">Filters and Map Key</h2>
                <button
                  onClick={() => setIsOpen(false)}
                  className="text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-200"
                  aria-label="Close sheet"
                >
                  <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                  </svg>
                </button>
              </div>

              <div className="space-y-4">
                <div className="border-b border-gray-200 dark:border-gray-700 pb-4">
                  <h3 className="font-medium mb-2 text-gray-900 dark:text-white">Map Key</h3>
                  <div className="space-y-2">
                    <div className="flex items-center gap-2">
                      <img src={greenIcon} alt="Low Price" className="w-6 h-6" />
                      <span className="text-sm text-gray-700 dark:text-gray-300">Less than £6</span>
                    </div>
                    <div className="flex items-center gap-2">
                      <img src={orangeIcon} alt="Medium Price" className="w-6 h-6" />
                      <span className="text-sm text-gray-700 dark:text-gray-300">Between £6-£7</span>
                    </div>
                    <div className="flex items-center gap-2">
                      <img src={redIcon} alt="High Price" className="w-6 h-6" />
                      <span className="text-sm text-gray-700 dark:text-gray-300">More than £7</span>
                    </div>
                    <div className="flex items-center gap-2">
                      <img src={blackIcon} alt="Guinness in Stock" className="w-6 h-6" />
                      <span className="text-sm text-gray-700 dark:text-gray-300">Guinness in Stock (24hr)</span>
                    </div>
                    <div className="flex items-center gap-2">
                      <img src={star} alt="Verified" className="w-6 h-6" />
                      <span className="text-sm text-gray-700 dark:text-gray-300">Multiple reports (3+ reports same price)</span>
                    </div>
                  </div>
                </div>

                <div>
                  <h3 className="font-medium mb-2 text-gray-900 dark:text-white">Pub Filters</h3>
                  <div className="space-y-3">
                    {filterOptions.map((option) => (
                      <div key={option.id} className="flex items-center justify-between">
                        <label
                          htmlFor={option.id}
                          className="text-sm text-gray-700 dark:text-gray-300"
                        >
                          {option.name}
                        </label>
                        <div className="relative inline-block w-10 mr-2 align-middle select-none transition duration-200 ease-in z-10">
                          <input
                            type="checkbox"
                            name={option.id}
                            id={option.id}
                            checked={
                              option.id === 'wetherspoons'
                                ? selectedFilters.wetherspoons === 'show'
                                : selectedFilters.amenities.includes(option.value)
                            }
                            onChange={() => handleFilterChange(option.id, option.value)}
                            className="sr-only peer"
                          />
                          <label
                            htmlFor={option.id}
                            className="block overflow-hidden h-6 rounded-full bg-gray-300 cursor-pointer peer-checked:bg-green-600 after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:after:translate-x-5"
                          ></label>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

MapControlSheet.propTypes = {
  onFilterChange: PropTypes.func.isRequired,
  setSelectedPintFilter: PropTypes.func.isRequired,
};

export default MapControlSheet;