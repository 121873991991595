import React, { useState, useEffect, useMemo } from "react";
import Downshift from "downshift";
import { searchPubs } from '../utils/pubLookupService';
import AddPubForm from './AddPubForm';
import { addManualPub } from '../utils/ManualPubHandle';
import { getRecentPubs } from '../utils/recentPubsService';

const PubsSelect = ({ selectedPub, onChange }) => {
  const [pubOptions, setPubOptions] = useState([]);
  const [recentPubs, setRecentPubs] = useState([]);
  const [userLocation, setUserLocation] = useState(null);
  const [locationPermissionGranted, setLocationPermissionGranted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showAddPubForm, setShowAddPubForm] = useState(false);
  const [lastSearchQuery, setLastSearchQuery] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [hasLoadedInitialPubs, setHasLoadedInitialPubs] = useState(false);
  const [isSearching, setIsSearching] = useState(false);

  useEffect(() => {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setUserLocation({
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
          });
          setLocationPermissionGranted(true);
        },
        (error) => {
          setLocationPermissionGranted(false);
        }
      );
    } else {
      setLocationPermissionGranted(false);
    }
  }, []);

  useEffect(() => {
    const handleStorageChange = (e) => {
      if (e.key === 'reportedPintDetails' || e.key === 'recentPubs') {
        loadRecentPubs();
      }
    };

    const loadRecentPubs = async () => {
      const recent = await getRecentPubs();
      setRecentPubs(recent);
    };

    loadRecentPubs();
    window.addEventListener('storage', handleStorageChange);
    
    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []);

  const fetchDefaultPubs = async (latitude, longitude) => {
    if (!locationPermissionGranted || hasLoadedInitialPubs || isSearching) return;
    
    setIsLoading(true);
    try {
      const results = await searchPubs("pub", { latitude, longitude }, true);
      
      if (Array.isArray(results)) {
        const highlightedResults = results.map(item => ({
          ...item,
          highlightedPubName: typeof item.pubName === 'string' ? highlightMatch(item.pubName, "") : item.pubName,
          highlightedRoadName: typeof item.roadName === 'string' ? highlightMatch(item.roadName, "") : item.roadName,
          highlightedTown: typeof item.town === 'string' ? highlightMatch(item.town, "") : item.town,
        }));
        setPubOptions(highlightedResults);
        setHasLoadedInitialPubs(true);
      } else {
        setPubOptions([]);
      }
    } catch (error) {
      setPubOptions([]);
    } finally {
      setIsLoading(false);
    }
  };
  
  const sortByProximity = (pubs) => {
    if (!userLocation) return pubs;
    
    return [...pubs].sort((a, b) => {
      if (!a.lat || !b.lat) return 0;
      const distA = getDistance(userLocation, { lat: a.lat, lng: a.lng });
      const distB = getDistance(userLocation, { lat: b.lat, lng: b.lng });
      return distA - distB;
    });
  };

  const highlightMatch = useMemo(() => (text, query) => {
    if (!query || !text) return text;
    try {
      // Normalize both the text and query using the same rules as the search
      const normalizedText = text.toLowerCase()
        .replace(/^(the\s+|ye\s+|ye\s+olde\s+|the\s+old\s+)/i, '')
        .replace(/\s+(pub|tavern|arms|inn|hotel|bar|house|hall)(\s|$)/g, '')
        .replace(/[^\w\s]/g, '')
        .trim();
      
      const normalizedQuery = query.toLowerCase()
        .replace(/^(the\s+|ye\s+|ye\s+olde\s+|the\s+old\s+)/i, '')
        .replace(/\s+(pub|tavern|arms|inn|hotel|bar|house|hall)(\s|$)/g, '')
        .replace(/[^\w\s]/g, '')
        .trim();

      // Create regex pattern from normalized query
      const regex = new RegExp(`(${normalizedQuery.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')})`, 'gi');
      
      // Split the original text (not normalized) based on matches in normalized text
      let lastIndex = 0;
      const parts = [];
      let match;
      
      while ((match = regex.exec(normalizedText)) !== null) {
        const matchStart = match.index;
        const matchEnd = matchStart + match[0].length;
        
        // Add the original text parts
        if (matchStart > lastIndex) {
          parts.push(text.slice(lastIndex, matchStart));
        }
        parts.push(text.slice(matchStart, matchEnd));
        lastIndex = matchEnd;
      }
      
      if (lastIndex < text.length) {
        parts.push(text.slice(lastIndex));
      }

      return (
        <>
          {parts.map((part, i) => 
            regex.test(part.toLowerCase()) ? 
              <mark key={i} className="bg-transparent font-bold text-green-600 dark:text-green-400">
                {part}
              </mark> : 
              part
          )}
        </>
      );
    } catch (error) {
      return text;
    }
  }, []);

  const handleSearch = async (inputValue) => {
    setSearchTerm(inputValue);
    setLastSearchQuery(inputValue);
    
    if (!inputValue) {
      setPubOptions([]);
      return;
    }

    setIsSearching(true);

    try {
      const results = await searchPubs(inputValue, userLocation, false);
      
      if (Array.isArray(results)) {
        const highlightedResults = results.map(item => ({
          ...item,
          highlightedPubName: typeof item.pubName === 'string' ? highlightMatch(item.pubName, inputValue) : item.pubName,
          highlightedRoadName: typeof item.roadName === 'string' ? highlightMatch(item.roadName, inputValue) : item.roadName,
          highlightedTown: typeof item.town === 'string' ? highlightMatch(item.town, inputValue) : item.town,
        }));
        if (
          JSON.stringify(highlightedResults) !== JSON.stringify(pubOptions)
        ) {
          setPubOptions(highlightedResults);
        }
      } else {
        setPubOptions([]);
      }
    } catch (error) {
      setPubOptions([]);
    } finally {
      setIsLoading(false);
      setIsSearching(false);
    }
  };

  useEffect(() => {
    if (userLocation && locationPermissionGranted && !hasLoadedInitialPubs) {
      fetchDefaultPubs(userLocation.latitude, userLocation.longitude);
    }
  }, [userLocation, locationPermissionGranted]);

  const MemoizedDownshift = useMemo(() => React.memo(({ children, inputValue }) => (
    <Downshift
      selectedItem={selectedPub}
      onChange={onChange}
      inputValue={inputValue}
      onInputValueChange={(value) => setSearchTerm(value)}
      itemToString={(item) => item ? item.pubName : ''}
    >
      {children}
    </Downshift>
  )), [selectedPub, onChange]);

  return (
    <MemoizedDownshift inputValue={searchTerm}>
      {({
        getInputProps,
        getMenuProps,
        getItemProps,
        highlightedIndex,
        isOpen,
        selectedItem,
        openMenu,
      }) => (
        <div className="relative">
          <div className="relative">
            <input
              {...getInputProps({
                placeholder: locationPermissionGranted 
                  ? "Select to see nearby or start typing..." 
                  : "Start typing to search pubs...",
                onFocus: () => {
                  openMenu();
                  if (userLocation && !hasLoadedInitialPubs) {
                    fetchDefaultPubs(userLocation.latitude, userLocation.longitude);
                  }
                },
                onChange: (e) => handleSearch(e.target.value),
                className: "w-full border border-gray-300 dark:border-gray-600 rounded px-3 py-2 text-lg dark:bg-gray-800 dark:text-white"
              })}
            />
            {isLoading && (
              <div className="absolute right-2 top-2">
                <div className="animate-spin h-5 w-5 border-2 border-green-500 dark:border-green-400 rounded-full border-t-transparent"></div>
              </div>
            )}
          </div>

          <div
            {...getMenuProps()}
            className={`absolute z-10 mt-1 w-full ${
              isOpen && (pubOptions.length > 0 || lastSearchQuery) ? "block" : "hidden"
            } bg-white dark:bg-gray-900 border dark:border-gray-700 rounded-lg shadow-lg max-h-[400px] overflow-auto`}
          >
            {pubOptions.map((item, index) => (
              <div
                {...getItemProps({
                  key: item.value || index,
                  index,
                  item,
                  disabled: item.isLabel,
                })}
                className={`${
                  highlightedIndex === index ? "bg-blue-50 dark:bg-blue-800" : ""
                } ${
                  item.isLabel ? "bg-gray-100 dark:bg-gray-800 font-semibold" : "hover:bg-blue-50 dark:hover:bg-blue-800"
                } py-3 px-4 cursor-pointer transition-colors duration-150`}
              >
                {item.isLabel ? (
                  <span className="text-sm text-gray-600 dark:text-gray-400">{item.label}</span>
                ) : (
                  <div>
                    <div className="font-medium text-lg dark:text-white">
                      {typeof item.pubName === 'string' ? highlightMatch(item.pubName, searchTerm) : item.pubName}
                    </div>
                    {(item.roadName || item.town) && (
                      <div className="text-sm text-gray-600 dark:text-gray-400">
                        {typeof item.roadName === 'string' && highlightMatch(item.roadName, searchTerm)}
                        {item.town && typeof item.town === 'string' && (
                          <>
                            {item.roadName && ', '}
                            {highlightMatch(item.town, searchTerm)}
                          </>
                        )}
                      </div>
                    )}
                  </div>
                )}
              </div>
            ))}
            
            {lastSearchQuery && !isLoading && (
              <div className="border-t dark:border-gray-700">
                <button
                  onClick={() => setShowAddPubForm(true)}
                  className="w-full text-left py-3 px-3 text-green-600 dark:text-green-400 hover:text-green-700 dark:hover:text-green-500 hover:bg-green-50 dark:hover:bg-green-900 font-medium"
                >
                  + Add "{lastSearchQuery}" as a new pub
                </button>
              </div>
            )}
          </div>

          {isOpen && pubOptions.length === 0 && lastSearchQuery && !isLoading && (
            <div className="absolute z-10 mt-2 w-full bg-white dark:bg-gray-900 border dark:border-gray-700 rounded shadow-md p-4">
              <p className="text-gray-600 dark:text-gray-400 mb-2">No pubs found matching "{lastSearchQuery}"</p>
              <button
                onClick={() => setShowAddPubForm(true)}
                className="text-green-600 dark:text-green-400 hover:text-green-700 dark:hover:text-green-500 font-medium"
              >
                + Add this pub
              </button>
            </div>
          )}

          {showAddPubForm && (
            <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
              <AddPubForm
                searchQuery={lastSearchQuery}
                onClose={() => setShowAddPubForm(false)}
                onSuccess={(newPub) => {
                  onChange(newPub);
                  setShowAddPubForm(false);
                  setPubOptions([]);
                  setLastSearchQuery('');
                }}
              />
            </div>
          )}
        </div>
      )}
    </MemoizedDownshift>
  );
};

// Helper function to calculate distance between two points
const getDistance = (point1, point2) => {
  const R = 6371; // Earth's radius in km
  const dLat = (point2.lat - point1.latitude) * Math.PI / 180;
  const dLon = (point2.lng - point1.longitude) * Math.PI / 180;
  const a = 
    Math.sin(dLat/2) * Math.sin(dLat/2) +
    Math.cos(point1.latitude * Math.PI / 180) * Math.cos(point2.lat * Math.PI / 180) * 
    Math.sin(dLon/2) * Math.sin(dLon/2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));
  return R * c;
};

export default PubsSelect;