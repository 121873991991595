import React, { useState, useEffect } from 'react';

const GuinnessInStockModal = () => {
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    const hasSeenModal = localStorage.getItem('hasSeenGuinnessModal');
    const hasDismissedModal = localStorage.getItem('hasDismissedGuinnessModal');

    // Show the modal if it hasn't been seen AND hasn't been dismissed
    if (!hasSeenModal && !hasDismissedModal) {
      setShowModal(true);
      localStorage.setItem('hasSeenGuinnessModal', 'true'); // Mark as seen
    }
  }, []);

  const closeModal = () => {
    setShowModal(false);
    // Set the hasDismissedModal flag in local storage when the modal is closed
    localStorage.setItem('hasDismissedGuinnessModal', 'true');
  };

  if (!showModal) return null;

  return (
    <div
      role="dialog"
      aria-labelledby="guinness-modal-title"
      aria-describedby="guinness-modal-description"
      className="fixed sm:bottom-4 bottom-20 w-full sm:max-w-sm z-[1200] bg-white dark:bg-gray-800 p-4 rounded-lg shadow-lg"
    >
      <img src='/black-icon.svg' alt="Guinness icon" />
      <h2 id="guinness-modal-title" className="text-lg font-semibold text-gray-900 dark:text-white">
        The Great Guinness Shortage!
      </h2>
      <p id="guinness-modal-description" className="mt-2 text-sm text-gray-700 dark:text-gray-300">
        Look for the black pint icon to find Guinness in stock. The icon will only be shown for 24 hours after a pint is reported.
      </p>
      <button
        onClick={closeModal}
        className="mt-4 px-4 py-2 bg-green-700 text-white rounded hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-400"
        aria-label="Close"
      >
        Got it!
      </button>
    </div>
  );
};

export default GuinnessInStockModal;