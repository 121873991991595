import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { usePintContext } from '../utils/PintContext';
import { useTheme } from '../utils/ThemeContext';
import logo from '../assets/CheapestPint.svg';
import logoDark from '../assets/CheapestPintDark.svg';
import logoMobile from '../assets/CPLogo.png';
import HamburgerMenu from './HamburgerMenu';
import ThemeToggle from './ThemeToggle';
import { useNavigate } from 'react-router-dom';

const Navbar = ({ setActiveTab }) => {
  const { reportedPints, currentLevel } = usePintContext();
  const { isDark } = useTheme();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 640);
  const navigate = useNavigate();
  const [isDisclaimerOpen, setIsDisclaimerOpen] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 640);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleSubmitPintClick = () => {
    setActiveTab("form");
    navigate('/#beerForm');
  };

  const handleLogoClick = (e) => {
    e.preventDefault();
    setActiveTab("map");
    navigate('/');
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const toggleDisclaimer = () => {
    setIsDisclaimerOpen(!isDisclaimerOpen);
  };

  const formatPintCount = () => {
    return `${reportedPints || 0} ${reportedPints === 1 ? 'pint' : ''} 🍺`;
  };

  return (
    <div className="dark:bg-gray-800">
      <div className="dark:bg-gray-800">
        <div className="bg-blue-100 text-center dark:bg-blue-900 p-2 lg:px-4">
          <div className="flex flex-col items-center">
            <div
              onClick={toggleDisclaimer}
              className="font-default text-xs text-blue-700 dark:text-blue-200 font-bold hover:text-blue-800 dark:hover:text-blue-100"
            >
              <span>Disclaimer: Pint prices listed here are crowdsourced and may not always be accurate or up-to-date. Please confirm with the venues for current pricing.</span>
            </div>
          </div>
        </div>
      </div>
      
      <nav className="container mx-auto max-w-7xl p-4 bg-white dark:bg-gray-800">
        <div className="container mx-auto flex flex-row justify-between items-center">
          <div className="flex items-center">
            <Link to="/" onClick={handleLogoClick}>
              <img 
                src={isMobile ? logoMobile : (isDark ? logoDark : logo)} 
                alt="Cheapest pint logo" 
                className="h-8 sm:h-12" 
              />
            </Link>
          </div>
          <div className="flex items-center gap-2">
            <button 
              onClick={handleSubmitPintClick} 
              className="inline-flex items-center px-4 py-3 bg-green-100 dark:bg-green-900 rounded-lg text-green-700 dark:text-green-200 font-semibold text-sm text-center hover:bg-green-300 dark:hover:bg-green-800 focus:outline-none focus:ring-2 focus:ring-green-300 dark:focus:ring-green-700 transition-all duration-300" 
              aria-label="Submit a pint"
            >
              {isMobile ? 'Submit' : 'Submit a pint'}
            </button>
            <div className="flex items-center">
              <Link 
                to="/my-pints" 
                className="text-sm bg-gray-100 px-4 py-3 dark:bg-gray-700 text-gray-600 dark:text-gray-300 hover:text-gray-800 dark:hover:text-gray-100 underline underline-offset-4 rounded-lg"
              >
                {formatPintCount()}
              </Link>
            </div>
            <ThemeToggle />
            <button
              onClick={toggleMenu}
              className="text-gray-600 dark:text-gray-300 hover:text-gray-800 dark:hover:text-gray-100 focus:outline-none rounded-lg bg-gray-100 dark:bg-gray-700 px-4 py-3"
              aria-label="Open menu"
            >
              <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16m-7 6h7"></path>
              </svg>
            </button>
          </div>
        </div>
      </nav>
      <HamburgerMenu 
        isOpen={isMenuOpen} 
        onClose={toggleMenu} 
        reportedPints={reportedPints}
        currentLevel={currentLevel}
      />
    </div>
  );
}

export default Navbar;