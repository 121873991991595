import { initializeApp } from 'firebase/app';
import { getDatabase, ref, query, orderByChild, startAt, endAt, get } from 'firebase/database';
import { database } from './ManualPubHandle';
import { firebaseConfig } from './firebaseConfig';
import { getRecentPubs } from './recentPubsService';
import { getDistance } from './distanceUtils';

// Initialize Firebase
const MAPBOX_API_KEY = process.env.REACT_APP_MAPBOX_API_KEY;
const MAPBOX_API_ENDPOINT = "https://api.mapbox.com/search/searchbox/v1";
const NOMINATIM_API_ENDPOINT = "https://nominatim.openstreetmap.org";

// Add debounce utility
const debounce = (func, wait) => {
  let timeout;
  return (...args) => {
    clearTimeout(timeout);
    return new Promise((resolve) => {
      timeout = setTimeout(() => resolve(func(...args)), wait);
    });
  };
};

const formatPubData = (pub) => ({
  value: pub.id,
  label: pub.name,
  pubName: pub.name,
  roadName: pub.road || '',
  town: pub.town || '',
  postcode: pub.postcode || '',
  isLabel: false,
  source: pub.source
});

const searchMapbox = async (query, proximity) => {
  if (!query) return [];

  try {
    const sessionToken = crypto.randomUUID();

    let proximityString;
    if (proximity && proximity.longitude && proximity.latitude) {
      proximityString = `${proximity.longitude},${proximity.latitude}`;
    }

    const suggestParams = new URLSearchParams({
      access_token: MAPBOX_API_KEY,
      session_token: sessionToken,
      q: query,
      limit: '10',
      language: 'en',
      country: 'gb',
      types: 'poi',
      poi_category: 'pub,bar,restaurant'
    });

    if (proximityString) {
      suggestParams.append('proximity', proximityString);
    }

    const suggestResponse = await fetch(
      `${MAPBOX_API_ENDPOINT}/suggest?${suggestParams}`
    );

    if (!suggestResponse.ok) {
      console.error('Mapbox Suggest API error status:', suggestResponse.status);
      const errorText = await suggestResponse.text();
      console.error('Mapbox error details:', errorText);
      throw new Error('Mapbox Suggest API error');
    }

    const suggestData = await suggestResponse.json();

    // Format the suggestions directly without retrieve endpoint
    return suggestData.suggestions.map(suggestion => {
      return formatPubData({
        id: `mapbox_${suggestion.mapbox_id}`,
        name: suggestion.name,
        road: suggestion.address || '',
        town: suggestion.place_formatted?.split(',')[0] || '',
        postcode: suggestion.context?.postcode?.name || '',
        source: 'mapbox',
        lat: suggestion.coordinates?.latitude,
        lng: suggestion.coordinates?.longitude
      });
    });

  } catch (error) {
    console.error('Mapbox Search API error:', error);
    return [];
  }
};

const searchNominatim = async (query) => {
  if (!query) return [];

  try {
    const params = new URLSearchParams({
      q: query,
      format: 'json',
      countrycodes: 'gb',
      limit: '10',
      addressdetails: '1',
      category: 'amenity:pub|amenity:bar|amenity:restaurant',
      extratags: '1',
      viewbox: '-8.74,60.86,1.96,49.84',
      bounded: '1'
    });

    const response = await fetch(
      `${NOMINATIM_API_ENDPOINT}/search?${params}`,
      {
        headers: {
          'User-Agent': 'PintPriceApp/1.0',
          'Accept-Language': 'en-GB'
        }
      }
    );

    if (!response.ok) {
      console.error('Nominatim API error status:', response.status);
      throw new Error('Nominatim API error');
    }

    const data = await response.json();
    console.log('Nominatim response:', data);

    return data
      .filter(place => {
        // Check if it's actually a pub/bar/restaurant
        const amenity = place.extratags?.amenity || place.type;
        const isValidType = ['pub', 'bar', 'restaurant'].includes(amenity?.toLowerCase());

        // Exclude parking spaces and other non-pub amenities
        const isNotParking = place.class !== 'amenity' || place.type !== 'parking';

        return isValidType && isNotParking;
      })
      .map(pub => formatPubData({
        id: `nominatim_${pub.place_id}`,
        name: pub.display_name.split(',')[0],
        road: pub.address?.road || '',
        town: pub.address?.city || pub.address?.town || '',
        postcode: pub.address?.postcode || '',
        source: 'nominatim',
        lat: parseFloat(pub.lat),
        lng: parseFloat(pub.lon)
      }));
  } catch (error) {
    return [];
  }
};

const deduplicateResults = (results) => {
  const seen = new Map();
  
  return results.filter(pub => {
    if (!pub || pub.isLabel) return true; // Keep label entries
    
    // Create a more robust key using multiple fields
    const key = [
      pub.pubName?.toLowerCase(),
      pub.roadName?.toLowerCase(),
      pub.town?.toLowerCase()
    ].filter(Boolean).join('_');
    
    if (seen.has(key)) {
      const existing = seen.get(key);
      // Prefer manual verified entries
      if (pub.source === 'manual' && pub.verified) {
        seen.set(key, pub);
        return true;
      }
      // Prefer Mapbox over Nominatim due to better data quality
      if (pub.source === 'mapbox' && existing.source === 'nominatim') {
        seen.set(key, pub);
        return true;
      }
      return false;
    }
    
    seen.set(key, pub);
    return true;
  });
};

const searchManualPubs = async (searchQuery) => {
  if (!searchQuery) return [];

  try {
    const pubsRef = ref(database, 'manualPubs');
    const pubQuery = query(
      pubsRef,
      orderByChild('pub'),
      startAt(searchQuery.toLowerCase()),
      endAt(searchQuery.toLowerCase() + '\uf8ff')
    );

    const snapshot = await get(pubQuery);
    const pubs = [];

    snapshot.forEach((child) => {
      const pub = child.val();
      pubs.push(formatPubData({
        id: child.key,
        name: pub.pub,
        road: pub.roadName,
        town: pub.town,
        postcode: pub.postcode,
        source: 'manual',
        verified: pub.verified || false
      }));
    });

    return pubs;
  } catch (error) {
    // console.error('Error searching manual pubs:', error);
    return [];
  }
};

const debouncedSearch = debounce(async (query, proximity) => {
  if (!query) return [];

  try {
    console.log('Starting search with query:', query, 'proximity:', proximity);

    // Run all searches in parallel
    const [mapboxResults, nominatimResults, manualResults] = await Promise.allSettled([
      searchMapbox(query, proximity),
      searchNominatim(query),
      searchManualPubs(query)
    ]);



    // Combine successful results
    const allResults = []
      .concat(
        mapboxResults.status === 'fulfilled' ? mapboxResults.value : [],
        nominatimResults.status === 'fulfilled' ? nominatimResults.value : [],
        manualResults.status === 'fulfilled' ? manualResults.value : []
      )
      .filter(Boolean);

    const dedupedResults = deduplicateResults(allResults);

    return dedupedResults;
  } catch (error) {
    console.error("Error in debouncedSearch:", error);
    return [];
  }
}, 500);

const combineWithRecentPubs = async (results, isNearbySearch = false) => {
  try {
    const recentPubs = await getRecentPubs();

    let combinedResults = [];

    // Add recent pubs if available
    if (recentPubs && recentPubs.length > 0) {
      combinedResults = [
        { isLabel: true, label: 'Recent Pubs' },
        ...recentPubs
      ];
    }

    // Add search results if available
    if (results && results.length > 0) {
      // Filter out duplicates with recent pubs
      const filteredResults = results.filter(pub =>
        !recentPubs.some(recent =>
          recent.pubName?.toLowerCase() === pub.pubName?.toLowerCase() &&
          recent.roadName?.toLowerCase() === pub.roadName?.toLowerCase()
        )
      );


      if (filteredResults.length > 0) {
        combinedResults.push(
          { isLabel: true, label: isNearbySearch ? 'Nearby Pubs & Bars' : 'Search Results' },
          ...filteredResults
        );
      }
    }

    return combinedResults;
  } catch (error) {
    return results; // Return original results if combination fails
  }
};

// Add at the top of the file
const searchCache = new Map();
const CACHE_DURATION = 24 * 60 * 60 * 1000;

const getCachedResults = (query, proximity) => {
  const cacheKey = `${query}_${Math.round(proximity?.latitude || 0)}_${Math.round(proximity?.longitude || 0)}`;
  const cached = searchCache.get(cacheKey);
  
  if (cached && (Date.now() - cached.timestamp) < CACHE_DURATION) {
    // Check if coordinates are within 0.5km of cached results
    if (proximity && cached.proximity) {
      const distance = getDistance(
        { lat: proximity.latitude, lng: proximity.longitude },
        { lat: cached.proximity.latitude, lng: cached.proximity.longitude }
      );
      if (distance < 500) { // 500 meters
        return cached.results;
      }
    } else if (!proximity && !cached.proximity) {
      return cached.results;
    }
  }
  
  return null;
};

const setCachedResults = (query, proximity, results) => {
  const cacheKey = `${query}_${proximity?.latitude}_${proximity?.longitude}`;
  searchCache.set(cacheKey, {
    results,
    timestamp: Date.now()
  });
};

const loadCacheFromStorage = () => {
  try {
    const stored = localStorage.getItem('pubSearchCache');
    if (stored) {
      const parsed = JSON.parse(stored);
      Object.entries(parsed).forEach(([key, value]) => {
        if (Date.now() - value.timestamp < CACHE_DURATION) {
          searchCache.set(key, value);
        }
      });
    }
  } catch (error) {
    console.error('Error loading cache:', error);
  }
};

const saveCacheToStorage = () => {
  try {
    const cacheObj = {};
    searchCache.forEach((value, key) => {
      cacheObj[key] = value;
    });
    localStorage.setItem('pubSearchCache', JSON.stringify(cacheObj));
  } catch (error) {
    console.error('Error saving cache:', error);
  }
};

export const searchPubs = async (query, proximity = null, isNearbySearch = false) => {
  // Don't search if query is too short
  if (query.length < 3) {
    return [];
  }

  try {
    // Check cache first
    const cachedResults = getCachedResults(query, proximity);
    if (cachedResults) {
      return await combineWithRecentPubs(cachedResults, isNearbySearch);
    }

    // If not cached, perform search
    const results = await debouncedSearch(query, proximity);

    // Cache the results
    setCachedResults(query, proximity, results);

    return await combineWithRecentPubs(results, isNearbySearch);
  } catch (error) {
    return [];
  }
};